import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import DigitalSolutionDevelopmentComponent from "../components/digital-solution-development/digital-solution-development"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { FAQSchemaGenerate } from "../schemas-org"

const query = graphql`
  query DigitalSolutionDE {
    datoCmsDigitalSolutionPage(locale: { eq: "de" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
      heading
      description
      headerBackground {
        url
      }
      sectionWithQuote {
        title
        text
        comment
        author
        authorPosition
      }
      smartSoftwareHeading
      smartSoftwareDescription
      smartSoftwareContent {
        image {
          alt
          gatsbyImageData
        }
        description
        link
        title
        id
        imageBackgroundColor {
          hex
        }
      }
      crossPlatformHeading
      crossPlatformContent
      crossPlatformImages {
        gatsbyImageData
        alt
      }
      customisedSolutionsHeading
      customisedSolutionsCards {
        icon{
          url
        }
        title
        content
      }
      phasesHeading
      phases {
        title
        phaseImage {
          gatsbyImageData
          alt
        }
        steps {
          title
          content
        }
      }
      collaborationModelHeading
      collaborationModels {
        title
        content
      }
      techStackHeading
      techStackSubtitle
      techStackImage {
        gatsbyImageData
        alt
      }
      techStackTypes {
        title
        technologies {
          title
        }
      }
      moreBlogsHeading
      contactImage {
        gatsbyImageData
        alt
      }
      showFaq
      faq {
        title
        faqItems {
          question
          answer
        }
        faqMoreQuestions
        faqConsultation
      }
      customSoftwareSolutionsHeading
      buttonCta
      callToAction
    }
    allDatoCmsBlogArticlesModel(
      filter: {
        showOnDigitalSolutionServicePage: { eq: true }
        published: {eq: true}
        locale: { eq: "de" }
      }
    ) {
      nodes {
        showOnDigitalSolutionServicePage
        title
        slug
        articleDate
        categories {
          id
          slug   
          name      
        }
        image {
          gatsbyImageData
          alt
        }
      }
    }
    allDatoCmsProject(
      filter: { showOnDigitalSolutionPage: { eq: true }, locale: { eq: "de" } }
    ) {
      nodes {
        title
        slug
        mainImage {
          alt
          gatsbyImageData(height: 260, layout: FIXED)
          url
        }
        projectBackground {
          hex
        }
        hashtags{
          hashtag
        }
      }
    }
  }
`

const DigitalSolutionDevelopment = props => {
  const data = useStaticQuery(query)
  const lang = props.pageContext.langKey
  const { seo } = data.datoCmsDigitalSolutionPage
  const { faq, showFaq } = data.datoCmsDigitalSolutionPage

  return (
    <Layout whiteNav isHome lang={lang} location={props.location} showContact={false}>
      <Seo
        title={seo.title}
        description={seo.description}
        meta={seo.description}
        image={seo && seo.image && seo.image.url}
        schemaMarkup={showFaq && FAQSchemaGenerate(faq[0].faqItems)}
        slug='software-engineering'
      />
      <DigitalSolutionDevelopmentComponent lang={lang} data={data} />
    </Layout>
  )
}

export default DigitalSolutionDevelopment
